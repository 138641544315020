import {
  ref, onMounted, getCurrentInstance, reactive
} from 'vue'
import { additionalFeeUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'

export default {
  name: 'AdditionalFeeForm',
  props: {
    dataEdit: {
      default: () => null
    }
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const store = useStore()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const dataEdit = ref(props.dataEdit)
    const title = ref('')
    const route = useRoute()
    const submitted = ref(false)

    const dataForm = reactive({
      code: '',
      name: '',
      color: '000000',
      ket: '',
      isActive: true
    })
    const rules = {
      code: {
        required: helpers.withMessage('Kode Biaya Tambahan harus diisi', required)
      },
      name: {
        required: helpers.withMessage('Nama Biaya Tambahan harus diisi', required)
      },
    }
    const v$ = useVuelidate(rules, dataForm)

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const getDataForm = async () => {
      title.value = 'Tambah Biaya Tambahan'
      if (dataEdit.value) {
        dataForm.code = dataEdit.value.Kode
        dataForm.name = dataEdit.value.Nama
        dataForm.isActive = dataEdit.value.IsActive
        dataForm.ket = dataEdit.value.Keterangan
        title.value = 'Edit Biaya Tambahan'
      }
    }

    // console.log('color', dataForm.color.includes('#'))
    const proccessSubmit = async () => {
      store.dispatch('showLoading')
      const data = {
        Kode: dataForm.code,
        Nama: dataForm.name,
        Keterangan: dataForm.ket,
        IsActive: dataForm.isActive
      }
      let id = ''
      if (dataEdit.value) {
        id = dataEdit.value.Id
      }
      console.log(data)
      const response = await additionalFeeUseCase.submitData(id, data)
      submitted.value = false

      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
        emit('reloadData')
        closeSidebar()
      }
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      proccessSubmit()
    }

    const deleteData = () => {
      if (!dataEdit.value) {
        $toast.add({
          severity: 'error', detail: 'Tidak dapat dihapus silahkan tunggu beberapa saat', group: 'bc', life: 3000
        })
        return
      }
      $confirm.require({
        header: 'Biaya Tambahan',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await additionalFeeUseCase.deleteData(dataEdit.value.Id)
          console.log('any resp', response)
          if (response.error) {
            $toast.add({
              severity: 'error', detail: response.result.detail, group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            emit('reloadData')
            closeSidebar()
          }
        }
      })
    }

    onMounted(() => {
      getDataForm()
    })
    return {
      title,
      dataEdit,
      route,
      v$,
      submitted,
      dataForm,
      submitData,
      deleteData,
      closeSidebar
    }
  }
}
